// FONT OXYGEN
@font-face {
  font-family: Oxygen;
  font-weight: 300;
  src: url("../fonts/Oxygen/Oxygen-Light.ttf");
  font-display: swap;
}

@font-face {
  font-family: Oxygen;
  font-weight: 400;
  src: url("../fonts/Oxygen/Oxygen-Regular.ttf");
  font-display: swap;
}

@font-face {
  font-family: Oxygen;
  font-weight: 700;
  src: url("../fonts/Oxygen/Oxygen-Bold.ttf");
  font-display: swap;
}

//
html body {
  @apply font-body antialiased;
}

h1,
h2,
h3,
h4 {
  @apply font-display;
}

:root {
  --font-display: Oxygen;
  --font-body: Oxygen;
}
